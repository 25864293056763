<template>
    <router-view />
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'Store',
    methods: mapActions([
        'getActiveStore',
        'getActiveLiveStore',
    ]),
    watch: {
        '$route.params.store'() {
            this.getActiveStore(this.$route.params.store)
            this.getActiveLiveStore(this.$route.params.store)
        },
    },
    created() {
        this.getActiveStore(this.$route.params.store)
        //this.getActiveLiveStore(this.$route.params.store)
    },
}
</script>

<style lang="scss">
@import '~scss/variables';
@import '~scss/mixins';

.page-content {
    background-color: $grey_light_2;
    padding-bottom: spacing(xxl);
    border-left: 1px solid $grey_light_1;
    min-height: calc(100vh - 105px);
}

.desktopNav {
    @include below(sm) {
        display: none;
    }
}
</style>
